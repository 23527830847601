import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { slugify } from "../../components/helpers"
import { useGetServices } from "../../hooks/useGetServices"
import { Colors, Sizes } from "../../components/theme"

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 2px solid ${Colors.scdsDark};
  border-radius: 5px;
  box-shadow: -5px 5px 0px -1px #036a6c2e;
  padding: 40px;
  a {
    text-decoration: none;
    font-size: 10px;
    color: ${Colors.scdsDark};
    margin-bottom: 35px;
  }

  @media (max-width: ${Sizes.mobile}) {
    grid-template-columns: 1fr 1fr;
  }
`

const Service = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 80px;
    text-decoration: none;
  }
`

const Services = () => {
  const services = useGetServices()

  const intl = useIntl()
  const locale = intl.locale !== "es" ? `${intl.locale}` : "es"

  return (
    <ServicesContainer>
      {locale === "es"
        ? services.spanish.nodes.map(service => {
            return (
              <Link
                to={`/es/servicios/${slugify(service.frontmatter.title)}`}
                key={service.id}
              >
                <Service key={service.id}>
                  <img
                    src={service.frontmatter.featuredImage.publicURL}
                    alt="Icono de enlace"
                  />
                  <h2>{service.frontmatter.title}</h2>
                </Service>
              </Link>
            )
          })
        : services.english.nodes.map(service => {
            return (
              <Link
                to={`/en/services/${slugify(service.frontmatter.title)}`}
                key={service.id}
              >
                <Service key={service.id}>
                  <img
                    src={service.frontmatter.featuredImage.publicURL}
                    alt="Icono de enlace"
                  />
                  <h2>{service.frontmatter.title}</h2>
                </Service>
              </Link>
            )
          })}
    </ServicesContainer>
  )
}

export default Services
