import { useStaticQuery, graphql } from "gatsby"

export const useGetHomePosts = () => {
  const posts = useStaticQuery(
    graphql`
      {
        english: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/posts/" }
            frontmatter: { language: { eq: "en" } }
          }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                date
                type
                description
                category
              }
            }
          }
        }
        spanish: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/posts/" }
            frontmatter: { language: { eq: "es" } }
          }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                date
                type
                description
                category
              }
            }
          }
        }
      }
    `
  )
  return posts
}
