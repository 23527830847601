import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import { useGetFeaturedPosts } from "../../hooks/useGetFeaturedPosts"

import { Colors, Sizes } from "../theme"

const MainBannerContainer = styled.div`
  border: 2px solid ${Colors.scdsDark};
  border-radius: 5px;
`

const Banner = styled.div`
  img {
    z-index: -1;
  }
`

const BannerInfo = styled.div`
  position: absolute;
  width: 790px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  text-align: center;
  h1 {
    font-size: 45px;
    margin: 0;
    color: ${Colors.scdsDark};
    font-weight: 900;
    width: 790px;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  h2 {
    font-size: 25px;
    margin: 0;
    color: ${Colors.scdsDark};
    font-weight: 600;
    width: 790px;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  @media (max-width: ${Sizes.mobile}) {
    height: auto;
    margin: 30px 0;
    width: 90%;
    h1,
    h2 {
      width: initial;
      font-size: 1rem;
      text-align: center;
    }
  }
`

const DefaultFeatured = styled.div`
  background: linear-gradient(45deg, #028285, #00b7ef);
  height: 300px;
`

const FeaturedHomePosts = () => {
  const featured = useGetFeaturedPosts()

  const intl = useIntl()
  const locale = intl.locale !== "es" ? `${intl.locale}` : "es"

  return (
    <MainBannerContainer>
      {locale === "es"
        ? featured.spanish.edges.slice(0, 1).map(post => {
            return (
              <Link to={post.node.fields.slug} key={post.node.id}>
                <Banner>
                  <BannerInfo>
                    <h1>{post.node.frontmatter.title}</h1>
                    <h2>{post.node.frontmatter.description}</h2>
                  </BannerInfo>
                  {post.node.frontmatter.featuredImage === null ? (
                    <DefaultFeatured />
                  ) : (
                    <Img
                      fluid={
                        post.node.frontmatter.featuredImage.childImageSharp
                          .fluid
                      }
                    />
                  )}
                </Banner>
              </Link>
            )
          })
        : featured.english.edges.slice(0, 1).map(post => {
            return (
              <Link to={post.node.fields.slug} key={post.node.id}>
                <Banner>
                  <BannerInfo>
                    <h1>{post.node.frontmatter.title}</h1>
                    <h2>{post.node.frontmatter.description}</h2>
                  </BannerInfo>
                  {post.node.frontmatter.featuredImage === null ? (
                    <DefaultFeatured />
                  ) : (
                    <Img
                      fluid={
                        post.node.frontmatter.featuredImage.childImageSharp
                          .fluid
                      }
                    />
                  )}
                </Banner>
              </Link>
            )
          })}
    </MainBannerContainer>
  )
}

export default FeaturedHomePosts
