import { useStaticQuery, graphql } from "gatsby"

export const useGetServices = () => {
  const services = useStaticQuery(
    graphql`
      {
        english: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/content/en/services/" } }
        ) {
          nodes {
            id
            frontmatter {
              title
              type
              featuredImage {
                publicURL
              }
            }
            fileAbsolutePath
          }
        }
        spanish: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/content/es/services/" } }
        ) {
          nodes {
            id
            frontmatter {
              title
              type
              featuredImage {
                publicURL
              }
            }
            fileAbsolutePath
          }
        }
      }
    `
  )
  return services
}
