import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useGetMonthlyPosts } from "../../hooks/useGetMonthlyPosts"

import { Colors } from "../../components/theme"
import { Container } from "../../components/styled/container"

import linkIcon from "../../../static/images/icon-link.png"

const Row = styled.article`
  font-family: "Inter";
  background: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  img {
    margin-right: 20px;
    width: 20px;
    height: 20px;
  }
  a {
    text-decoration: none;
  }
  h2 {
    color: ${Colors.scdsDark};
    font-size: 17px;
    font-weight: 600;
  }
  h3 {
    font-size: 10px;
    color: ${Colors.scdsLight};
    margin-right: 15px;
    text-transform: uppercase;
  }
`

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`

const FeaturedHomePosts = () => {
  const posts = useGetMonthlyPosts()
  const intl = useIntl()
  const locale = intl.locale !== "es" ? `${intl.locale}` : "es"

  return (
    <Container>
      <RowsContainer>
        {locale === "es"
          ? posts.spanish.edges.map(value => {
              let post = value.node.frontmatter
              return (
                <Row key={value.node.id}>
                  <img src={linkIcon} alt="Icono de enlace" />
                  <Link to={value.node.fields.slug}>
                    <h2>{post.title}</h2>
                  </Link>
                </Row>
              )
            })
          : posts.english.edges.map(value => {
              let post = value.node.frontmatter
              return (
                <Row key={value.node.id}>
                  <img src={linkIcon} alt="Icono de enlace" />
                  <Link to={value.node.fields.slug}>
                    <h2>{post.title}</h2>
                  </Link>
                </Row>
              )
            })}
      </RowsContainer>
    </Container>
  )
}

export default FeaturedHomePosts
