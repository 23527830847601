import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/structure/layout"
import SEO from "../components/structure/seo"
import FeaturedHomePosts from "../components/structure/featuredHomePosts"
import MonthHomePosts from "../components/structure/monthHomePosts"
import Services from "../components/structure/services"
import MainBanner from "../components/structure/mainBanner"
import { Container } from "../components/styled/container"
import { Separator } from "../components/styled/separator"
import { Colors } from "../components/theme"

const FeaturedHomePostHeroContainer = styled.section`
  background: linear-gradient(
    to bottom,
    #ffffff 15%,
    ${Colors.scdsMain} 15%,
    ${Colors.scdsMain} 85%,
    ${Colors.scdsMain} 85%,
    #ffffff 85%
  );
`

const FeaturedPostsText = styled.h4`
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 35px 0;
`

const Index = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title="Inicio" />
      <Container>
        <MainBanner />
        <FeaturedPostsText>
          {intl.formatMessage({ id: "home_featured_posts" })}
        </FeaturedPostsText>
      </Container>
      <FeaturedHomePostHeroContainer>
        <FeaturedHomePosts />
      </FeaturedHomePostHeroContainer>
      <Container>
        <FeaturedPostsText>
          {intl.formatMessage({ id: "home_last_month_posts" })}
        </FeaturedPostsText>
        <MonthHomePosts />
        <Separator />
        <FeaturedPostsText>
          {intl.formatMessage({ id: "header_services" })}
        </FeaturedPostsText>
        <Services />
      </Container>
    </Layout>
  )
}

export default Index
