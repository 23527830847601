import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useGetHomePosts } from "../../hooks/useGetHomePosts"
import { Colors, Sizes } from "../../components/theme"
import { Container } from "../../components/styled/container"

const Card = styled.article`
  font-family: "Inter";
  background: white;
  border-radius: 5px;
  border: 2px solid ${Colors.scdsDark};
  padding: 10px 20px;
  text-decoration: none;
  font-size: 13px;
  flex-basis: 0;
  flex-grow: 1;
  min-height: 260px;
  box-shadow: -5px 5px 0px -1px #036a6c2e;
  a {
    text-decoration: none;
  }
  h2 {
    color: ${Colors.scdsDark};
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }

  h3 {
    font-size: 10px;
    color: ${Colors.scdsLight};
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }

  p {
    font-family: "Merriweather";
    font-size: 13px;
    line-height: 17px;
  }
`
const Categories = styled.div`
  display: flex;
`

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: ${Sizes.mobile}) {
    grid-template-columns: 1fr;
  }
`

const FeaturedHomePosts = () => {
  const posts = useGetHomePosts()

  const intl = useIntl()
  const locale = intl.locale !== "es" ? `${intl.locale}` : "es"

  return (
    <Container>
      <CardsContainer>
        {locale === "es"
          ? posts.spanish.edges.slice(0, 3).map(value => {
              let post = value.node.frontmatter
              return (
                <Card key={value.node.id}>
                  <Link to={value.node.fields.slug}>
                    <h2>{post.title}</h2>
                  </Link>
                  <Categories>
                    {post.category.map(item => {
                      return <h3 key={item}>{item}</h3>
                    })}
                  </Categories>
                  <p>{post.description}</p>
                </Card>
              )
            })
          : posts.english.edges.slice(0, 3).map(value => {
              let post = value.node.frontmatter
              return (
                <Card key={value.node.id}>
                  <Link to={value.node.fields.slug}>
                    <h2>{post.title}</h2>
                  </Link>
                  <Categories>
                    {post.category.map(item => {
                      return <h3 key={item}>{item}</h3>
                    })}
                  </Categories>
                  <p>{post.description}</p>
                </Card>
              )
            })}
      </CardsContainer>
    </Container>
  )
}

export default FeaturedHomePosts
