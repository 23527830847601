import styled from "styled-components"
import { Colors } from "../../components/theme"

export const Separator = styled.hr`
  border-color: ${Colors.scdsDark};
  background: none;
  border-width: 1px;
  border-top: 0px;
  margin: 45px 0;
  border-right: 0px;
  border-left: 0;
`
