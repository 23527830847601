import { useStaticQuery, graphql } from "gatsby"

export const useGetFeaturedPosts = () => {
  const posts = useStaticQuery(
    graphql`
      {
        english: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/posts/" }
            frontmatter: { featured: { eq: true }, language: { eq: "en" } }
          }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                date
                type
                category
                description
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 790, maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        spanish: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/posts/" }
            frontmatter: { featured: { eq: true }, language: { eq: "es" } }
          }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                date
                type
                category
                description
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 790, maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return posts
}
